/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component() {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
<div class="page">
  
  <div class="navbar">
    <div class="navbar-bg"></div>
    <div class="navbar-inner sliding">
      <div class="left">
        <a href="#" class="back link">
          <i class="icn icn-back"></i>
          <span>Назад</span>
        </a>
      </div>
      <div class="title">
        
      </div>
    </div>
  </div>

  <div class="page-content">
    <div class="block">
    </div>
  </div>
</div>
`
    }
    ;
}
framework7Component.id = '4c95a8ac61';
export default framework7Component;