/** @jsx $jsx */
import { $jsx } from 'framework7';
// import params from "../js/params.js";
// const API_URL = params.apiUrl;
// const BACKEND_URL = params.backendUrl;

var API_URL = "https://api.may.rdbx24.ru";
var BACKEND_URL = "https://may.rdbx24.ru";
var upd = 1;
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $ = _ref.$,
    $f7 = _ref.$f7,
    $onMounted = _ref.$onMounted,
    $update = _ref.$update;
  var categories = [];
  var fileUrl = BACKEND_URL;
  var is_loading = true;
  var swiper = null;
  var popup = null;
  $on('pageInit', function () {
    if (upd === 1 && !localStorage.getItem('hide_hello')) {
      popup = $f7.popup.create({
        el: '.popup-hello',
        on: {
          open: function open(popup) {
            swiper = $f7.swiper.create('.swiper-hello', {
              pagination: {
                el: ".swiper-pagination"
              }
            });
          }
        }
      });
      popup.open();
    }
    upd++;
    $f7.request.json("".concat(API_URL, "/v1/categories"), {
      expand: 'parent,categories,posts',
      'filter[parent_id]': 'null'
    }).then(function (response) {
      categories = response.data.items;
      $update();
    }).finally(function () {
      is_loading = false;
      $update();
    });
  });
  $(document).on("click", ".swiper-hello-btn", function () {
    localStorage.setItem('hide_hello', 1);
    if (swiper) {
      if (swiper.activeIndex === 2) {
        popup.close();
      }
      swiper.slideNext();
    }
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">

    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="title title-image">
          <svg width="55" height="17" viewBox="0 0 55 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.7647 0.200195V17.0002H13.4927V7.9522L9.41266 14.6962H8.98066L4.92466 7.9762V17.0002H0.628662V0.200195H4.92466L9.19666 7.3762L13.4927 0.200195H17.7647Z"
              fill="#25A82B" />
            <path
              d="M33.6814 17.0002L32.9613 14.5762H27.3694L26.6493 17.0002H21.9933L27.5373 0.200195H32.7694L38.3134 17.0002H33.6814ZM28.4734 10.8802H31.8573L30.1534 5.1682L28.4734 10.8802Z"
              fill="#25A82B" />
            <path
              d="M54.6827 0.200195L49.0187 10.5442V17.0002H44.7227V10.5922L39.0587 0.200195H43.8827L46.8587 6.4162L49.8587 0.200195H54.6827Z"
              fill="#25A82B" />
          </svg>
        </div>
        <div class="right">
          <a href="#" class="link link-telegram">
            <i class="icn icn-telegram"></i>
        </a>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="block block-grid">
        ${categories.map((item) => $h`
        <a href="/category/${item.id}/" class="index-card">
          <div class="index-card-title">${item.title}</div>
          <div class="index-card-text">${item.short_description}</div>
          <img class="index-card-image" src="${fileUrl}/index.php?r=site/file&id=${item.thumbnail_id}" alt="" />
        </a>
        `)}
      </div>

    </div>

    <div class="popup popup-hello">
      <div class="block">
        <div class="swiper-container swiper-hello">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="swiper-hello-img1"></div>

              <h2 class="text-align-center">Привет!</h2>
              <p>Рады видеть вас в нашем приложении. Именно благодаря ему вы сможете стать юридически грамотным
                человеком. </p>
              <p>Для этого вам даже не понадобится тратить кучу денег и времени на поход к юристу. Вы сможете сами
                решать свои проблемы.</p>
            </div>

            <div class="swiper-slide">
              <h2>С помощью данного приложения вы найдете ответы на вопросы:</h2>
              <ul>
                <li>Как вернуть товар, заказанный в интернет-магазине?</li>
                <li>Какие действия нужно совершить при эвакуации авто?</li>
                <li>Как законно избавиться от долга в размере более 500 000 рублей?</li>
              </ul>
              <p>Мы поможем вам найти выход из любой ситуации.</p>
            </div>

            <div class="swiper-slide">
              <div class="swiper-hello-img2"></div>
              <h2 class="text-align-center">Добро пожаловать в May!</h2>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="swiper-hello-footer">
        <div class="block">
          <button type="button" class="btn swiper-hello-btn">Далее</button>
        </div>
      </div>
    </div>

  </div>
`
    }
    ;
}
framework7Component.id = '25f24e0d64';
export default framework7Component;