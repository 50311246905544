/** @jsx $jsx */
import { $jsx } from 'framework7';
// import params from "../js/params.js";
// const API_URL = params.apiUrl;
// const BACKEND_URL = params.backendUrl;

var API_URL = "https://api.may.rdbx24.ru";
var BACKEND_URL = "https://may.rdbx24.ru";
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $ = _ref.$,
    $f7 = _ref.$f7,
    $store = _ref.$store;
  function setPhoneMask(element) {
    if (element) {
      IMask(element, {
        mask: '+{7} (000) 000-00-00'
      });
    }
  }
  var quiz = $store.getters.quiz.value;
  var onSubmit = function onSubmit(e) {
    e.preventDefault();
    var formData = $f7.form.convertToData('#quiz-success-form');
    var formSend = {
      title: 'Банкротство опрос',
      phone: formData.phone,
      form_id: quiz.form_id,
      category_id: quiz.category_id,
      service_id: quiz.service_id,
      items: JSON.stringify(quiz)
    };
    $f7.request.post("".concat(API_URL, "/v1/orders"), formSend).then(function (response) {
      $store.dispatch('resetQuiz');
      $f7.dialog.alert('Ожидайте звонка специалиста', 'Успешно', function () {
        $f7.tab.show("#view-home");
        $f7.views.home.router.navigate("/");
      });
    }).catch(function (err) {
      $f7.dialog.alert('Ошибка отправки формы', 'Ошибка');
    });
  };
  $on('pageInit', function () {
    var element = document.getElementById("quiz-phone");
    setPhoneMask(element);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page">
        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#view-home" class="tab-link link">
                        <i class="icn icn-back"></i>
                        <span>Главная</span>
                    </a>
                </div>
                <div class="title">Опрос</div>
            </div>
        </div>

        <div class="page-content">

            <h1 class="text-align-center">Спасибо за пройденный<br />опрос!</h1>
            <div class="block text-align-center">
                <p>Оставьте, пожалуйста, ваш номер телефона, наш специалист по Банкротству обязательно с вами свяжется в
                    ближайшее время</p>
            </div>
            <div class="block">
                <form @submit=${onSubmit} id="quiz-success-form">
                    <div class="form-row">
                        <input id="quiz-phone" required type="tel" class="text-align-center" name="phone" placeholder="+7 (___) ___-__-__" />
                    </div>
                    <div>
                        <button type="submit" class="btn-disable btn">
                            Отправить
                        </button>
                    </div>
                </form>
            </div>

        </div>
    </div>
`
    }
    ;
}
framework7Component.id = 'a2400000c5';
export default framework7Component;