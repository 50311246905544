import $ from 'dom7';
import Framework7, { getDevice } from 'framework7/bundle';

// Import F7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
import '../css/img.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import Routes
import routes from './routes.js';
// Import Store
import store from './store.js';

// Import main app component
import App from '../app.f7.html';

var device = getDevice();

var app = new Framework7({
  name: 'MayApp', // App name
  theme: 'auto', // Automatic theme detection
  el: '#app', // App root element
  component: App, // App main component
  id: 'ru.rdbx24.may', // App bundle ID
  // App store
  store: store,
  // App routes
  routes: routes,
  // Input settings
  input: {
    scrollIntoViewOnFocus: device.cordova && !device.electron,
    scrollIntoViewCentered: device.cordova && !device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
    androidBackgroundColor: "#F9F9F9",
    iosBackgroundColor: "#F9F9F9",
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
      }
    },
  },
});

const TELEGRAM_LINK = 'https://t.me/Dmitriyzelenov';

$(document).on('click', 'a.link-telegram', function(e) {
  e.preventDefault();

  if (typeof(cordova) != "undefined" && cordova !== null && cordova.InAppBrowser) {
    cordova.InAppBrowser.open(TELEGRAM_LINK, '_system', 'location=yes');
  } else {
    window.open(TELEGRAM_LINK, '_system', 'location=yes');
  }
});

$(document).on('click', '.link-payment', function(e) {
  e.preventDefault();

  if (typeof(cordova) != "undefined" && cordova !== null && cordova.InAppBrowser) {
    cordova.InAppBrowser.open($(this).attr("href"), '_system', 'location=yes');
  } else {
    window.open($(this).attr("href"), '_system', 'location=yes');
  }
});
