/** @jsx $jsx */
import { $jsx } from 'framework7';
var TELEGRAM_LINK = 'https://t.me/Dizeet';
function framework7Component(props, _ref) {
  var $ = _ref.$,
    $f7 = _ref.$f7,
    $update = _ref.$update,
    $store = _ref.$store;
  var openCallback = function openCallback() {
    var dynamicSheet = $f7.sheet.create({
      content: "\n            <div class=\"sheet-modal\" style=\"height:auto;\">\n              <div class=\"sheet-modal-inner callback-view\">\n                <div class=\"block-title callback-view-title\">\u041A\u043E\u043D\u0441\u0443\u043B\u044C\u0442\u0430\u0446\u0438\u044F</div>\n                <div class=\"block callback-view-content\">\n                  <p><b>\u041F\u043E\u0437\u0432\u043E\u043D\u0438\u0442\u0435 \u043F\u043E \u043D\u043E\u043C\u0435\u0440\u0443:</b></p>\n                  <p>\n                    <a href=\"tel:88005553535\">\n                      <i class=\"icn icn-phone-outline\"></i>\n                      8 800 555 35 35\n                    </a>\n                  </p>\n                  <small>\u0438 \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u0435 \u043A\u043E\u043D\u0441\u0443\u043B\u044C\u0442\u0430\u0446\u0438\u044E<br />\u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u0430</small>\n                </div>\n              </div>\n            </div>\n          ",
      swipeToClose: true,
      backdrop: true
    });
    dynamicSheet.open();
  };
  $(document).on('click', '.toolbar a[href="#view-home"]', function () {
    $store.dispatch('resetQuiz');
    $store.dispatch('resetOrder');
    $store.dispatch('resetFssp');
    $f7.tab.show("#view-home");
    $f7.views.home.router.navigate("/", {
      clearPreviousHistory: true,
      ignoreCache: true
    });
  });

  // $(document).on('click', 'a.link-telegram', function(e) {
  //   e.preventDefault();

  //   if ($f7.device.cordova) {
  //     $f7.device.cordova.InAppBrowser.open(TELEGRAM_LINK, '_system', 'location=yes');
  //   }
  // });

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app">

    <div class="views tabs safe-areas">

      <div class="toolbar toolbar-bottom tabbar-labels">
        <div class="toolbar-inner">
          <a href="#view-home" data-force="true" data-ignore-cache="true" data-animate="false" data-reload-all="true" class="tab-link tab-link-active">
            <i class="icn icn-home"></i>
          </a>
          <a href="#" class="link">
            <i class="icn icn-user"></i>
          </a>
          <a href="#" class="link" @click="${() => openCallback()}">
            <i class="icn icn-phone"></i>
          </a>
        </div>
      </div>

      <div id="view-home" class="view view-main view-init tab tab-active" data-name="home" data-url="/">
      </div>

      <div id="view-forms" class="view view-forms view-init tab" data-name="forms">
      </div>

      <div id="view-services" class="view view-services view-init tab" data-name="services">
      </div>

      <div id="view-quiz" class="view view-quiz view-init tab" data-name="quiz">
      </div>
      
      <div id="view-fssp" class="view view-fssp view-init tab" data-name="fssp">
      </div>
    </div>

  </div>
`
    }
    ;
}
framework7Component.id = '52747bf9d8';
export default framework7Component;