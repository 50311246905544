/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $ = _ref.$,
    $f7 = _ref.$f7,
    $f7route = _ref.$f7route,
    $store = _ref.$store,
    $update = _ref.$update;
  var quiz = $store.getters.quiz.value;
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page">

        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#view-home" class="tab-link link">
                        <i class="icn icn-back"></i>
                        <span>Главная</span>
                    </a>
                </div>
                <div class="title">Опрос</div>
            </div>
        </div>

        <div class="page-content">

            <h1 class="text-align-center">Мы проанализировали<br />ваши ответы</h1>
            <div class="block">
                <p>Для того, чтобы подобрать наиболее оптимальное решение по вашему вопросу, <b>необходимо пройти
                        небольшой опрос.</b></p>
                <p>После с вами свяжется <b>специалист по Банкротсву</b> и поможет решить вашу проблему.</p>

            </div>

            ${quiz && quiz.form_id && $h`
                <div class="block">
                    <a href="/quiz/${quiz.form_id}/" class="btn">Пройти опрос</a>
                </div>
            `}

        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '39e978fcc5';
export default framework7Component;