/** @jsx $jsx */
import { $jsx } from 'framework7';
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $ = _ref.$,
    $f7 = _ref.$f7,
    $f7route = _ref.$f7route,
    $store = _ref.$store,
    $update = _ref.$update;
  var fssp = $store.getters.fssp.value;
  var order = $store.getters.order.value;

  // console.group("fssp page");
  // console.log('fssp', fssp);
  // console.log('order', order);
  // console.groupEnd();

  // "name": "ЖЕЛТЯКОВ АЛЕКСЕЙ МИХАЙЛОВИЧ 23.04.1974 РЕСП. ТАТАРСТАН, Г. НАБЕРЕЖНЫЕ ЧЕЛНЫ",
  // "exe_production": "350639/21/16003-ИП от 28.05.2021",
  // "details": "Исполнительный лист от 08.04.2021 № А65-11627/2018 АРБИТРАЖНЫЙ СУД РЕСПУБЛИКИ ТАТАРСТАН",
  // "subject": "Госпошлина, присужденная судом: 6000 руб.",
  // "department": "Вахитовское РОСП г.Казани УФССП России по Республике Татарстан 420097, Россия, Респ. Татарстан, , г. Казань, , ул. Рабочей Молодежи, д. 9, , ",
  // "bailiff": "ХАЙДАРОВ А. Ф.<br>+78435149221",
  // "ip_end": "",
  // "form_id": 9

  var onSubmit = function onSubmit(e) {
    e.preventDefault();
    var formData = $f7.form.convertToData('#fssp-form');
    $store.dispatch('updateOrder', {
      form_id: formData.form_id
    });
    $update().then(function () {
      $store.dispatch('updateStep', 1);
      $f7.tab.show("#view-services");
      $f7.views.services.router.navigate("/services/".concat(order.category_id, "/"));
    });
  };
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page">

        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#view-home" class="tab-link link">
                        <i class="icn icn-back"></i>
                        <span>Главная</span>
                    </a>
                </div>
                <div class="title">Проверка задолженности</div>
            </div>
        </div>

        <div class="page-content">
            ${fssp && fssp.items && $h`
                <form @submit=${onSubmit} id="fssp-form">
                    <div class="block">
                        ${fssp.items.map((item) => $h`
                        <label class="service-card">
                            <div class="service-card-header">
                                <h2 class="service-card-title">${item.subject}</h2>
                                <input required type="radio" name="form_id" value="${item.form_id}" />
                                <i class="icn icn-checkbox"></i>
                            </div>

                            <div class="service-card-body">
                                ${item.details}
                            </div>
                        </label>
                        `)}
                        <button type="submit" class="btn">Решить проблему</button>
                    </div>
                </form>
            `}
        </div>

    </div>
`
    }
    ;
}
framework7Component.id = 'f5f1176e9c';
export default framework7Component;