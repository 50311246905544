/** @jsx $jsx */
import { $jsx } from 'framework7';
// import params from "../js/params.js";
// const API_URL = params.apiUrl;
// const BACKEND_URL = params.backendUrl;

var API_URL = "https://api.may.rdbx24.ru";
var BACKEND_URL = "https://may.rdbx24.ru";
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $ = _ref.$,
    $f7 = _ref.$f7,
    $f7router = _ref.$f7router,
    $onMounted = _ref.$onMounted,
    $store = _ref.$store,
    $update = _ref.$update;
  var id = props.id,
    groups = props.groups;
  var form = id;
  var onSubmit = function onSubmit(e) {
    e.preventDefault();
    var formData = $f7.form.convertToData('#quiz-view-form');
    $store.dispatch('updateQuiz', formData);
    $f7.views.quiz.router.navigate("/quiz/success/");
  };
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page">

        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#view-home" class="tab-link link">
                        <i class="icn icn-back"></i>
                        <span>Назад</span>
                    </a>
                </div>
                <div class="title">
                    Опрос
                </div>
            </div>
        </div>

        <div class="page-content">

            ${form && $h`
            <div class="block">
                <form @submit=${onSubmit} id="quiz-view-form">
                    ${groups.map((group) => $h`
                    <div class="quiz-item">
                        <div class="quiz-item-title">${group.title}</div>

                        ${group.fields.map((field) => $h`
                        <!-- <label class="quiz-checkbox">
                            <input type="${field.type}" name="${field.code}" value="${field.value}" />
                            <i class="icn icn-checkbox"></i>
                            <div class="quiz-checkbox-label">${field.title}</div>
                        </label> -->
                        <div class="quiz-checkbox">
                            <label class="toggle toggle-init color-green">
                              <input type="${field.type}" name="${field.code}" value="${field.value}" />
                              <span class="toggle-icon"></span>
                            </label>
                            <div class="quiz-checkbox-label">${field.title}</div>
                          </div>
                        `)}
                    </div>
                    `)}
                    <div>
                        <button type="submit" class="btn">Отправить</button>
                    </div>
                </form>
            </div>
            `}
        </div>

    </div>
`
    }
    ;
}
framework7Component.id = 'd3ddc9b3f8';
export default framework7Component;