/** @jsx $jsx */
import { $jsx } from 'framework7';
// import params from "../js/params.js";
// const API_URL = params.apiUrl;
// const BACKEND_URL = params.backendUrl;

var API_URL = "https://api.may.rdbx24.ru";
var BACKEND_URL = "https://may.rdbx24.ru";
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $ = _ref.$,
    $f7 = _ref.$f7,
    $store = _ref.$store,
    $update = _ref.$update,
    $onMounted = _ref.$onMounted;
  // let services = [];

  var service = $store.getters.service.value;
  var step = $store.getters.step.value;
  var order = $store.getters.order.value;
  var paymentUrl = $store.getters.paymentUrl.value;
  var timerId = null;
  var services = props.services;
  function setPhoneMask(element) {
    if (element) {
      IMask(element, {
        mask: '+{7} (000) 000-00-00'
      });
    }
  }
  var onSubmit = function onSubmit(e) {
    e.preventDefault();
    var data = $f7.form.convertToData('#services-form');
    $store.dispatch('updateOrder', data);
    if (step == 1) {
      if (data.service_id && services.length) service = services.filter(function (item) {
        return item.id == data.service_id;
      })[0];
      $store.dispatch('updateService', service);
      $store.dispatch('updateStep', 2);
      step = $store.getters.step.value;
      $update().then(function () {
        var element = document.getElementById("servicePhone");
        setPhoneMask(element);
      });
      return;
    }
    if (step == 2) {
      $store.dispatch('updateOrder', $f7.form.convertToData('#services-form'));
      var order = $store.getters.order.value;
      $f7.request.post("".concat(API_URL, "/v1/orders"), order).then(function (response) {
        var responseData = JSON.parse(response.data);
        $store.dispatch('updateOrder', responseData);
        $store.dispatch('updateStep', 3);
        $f7.request.get("".concat(API_URL, "/payment?id=").concat(responseData.id)).then(function (responsePayment) {
          var responsePaymentData = JSON.parse(responsePayment.data);
          $store.dispatch('updatePaymentUrl', responsePaymentData.formUrl);
          step = $store.getters.step.value;
          paymentUrl = $store.getters.paymentUrl.value;
          $update().then(function () {
            $('.link-payment').click();
            timerId = setInterval(function () {
              $('.check-payment-status').click();
            }, 5000);
            setTimeout(function () {
              clearInterval(timerId);
            }, 100000);
          });
        });
      }).catch(function (err) {
        console.log('err', err);
      });
      return;
    }
    if (step == 3) {
      var order = $store.getters.order.value;
      $f7.request.get("".concat(API_URL, "/payment-status?id=").concat(order.id)).then(function (responsePaymentStatus) {
        var responsePaymentStatusData = JSON.parse(responsePaymentStatus.data);
        if (responsePaymentStatusData && responsePaymentStatusData.OrderStatus) {
          $store.dispatch('updateService', null);
          $store.dispatch('updateStep', 1);
          $update().then(function () {
            clearInterval(timerId);
            $f7.tab.show("#view-forms");
            $f7.views.forms.router.navigate("/form/".concat(order.form_id, "/"));
          });
        }
      });
    }
  };
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page" data-name="services">

        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#view-home" class="tab-link link">
                      <i class="icn icn-back"></i>
                      <span>Назад</span>
                    </a>
                  </div>
                <div class="title">Выбрать услугу</div>
            </div>
        </div>

        <div class="page-content">

            <form @submit=${onSubmit} id="services-form">
                ${step && step == 1 && $h`
                <div class="block">
                    ${services.map((item) => $h`
                    <label class="service-card">
                        <div class="service-card-header">
                            <h2 class="service-card-title">${item.title}</h2>
                            <input required type="radio" name="service_id" value="${item.id}" />
                            <i class="icn icn-checkbox"></i>
                        </div>

                        <div class="service-card-body">
                            ${item.short_description}
                        </div>

                        <div class="service-card-footer">
                            <h3>Стоимость услуги:</h3>
                            <!-- <div>
                                <span class="price-old">${item.price} ₽</span>
                                <span class="price-new">
                                    ${item.price} ₽
                                    <sup>-50%</sup>
                                </span>
                            </div> -->
                            <div>
                                <span class="price-new">
                                    ${item.price} ₽
                                </span>
                            </div>
                        </div>
                    </label>
                    `)}

                    <button type="submit" class="btn">Далее</button>
                </div>
                `}

                ${step && step == 2 && $h`
                <div class="block">

                    <div class="mb-5">
                        <div class="form-row">
                            <div class="input-wrap">
                                <label for="">Телефон</label>
                                <input id="servicePhone" required type="tel" name="phone" placeholder="+7 (___) ___-__-__" />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="input-wrap">
                                <label for="">E-mail</label>
                                <input id="serviceEmail" required type="email" name="email" placeholder="mail@mail.ru" />
                            </div>
                        </div>
                    </div>
                    ${service && $h`
                    <div>
                        <div class="service-card mb-5">
                            <div class="service-card-header">
                                <h2 class="service-card-title">${service.title}</h2>
                            </div>

                            <div class="service-card-body">
                                ${service.short_description}
                            </div>

                            <div class="service-card-footer">
                                <h3>Стоимость услуги:</h3>
                                <!-- <div>
                                    <span class="price-old">${service.price} ₽</span>
                                    <span class="price-new">
                                        ${service.price} ₽
                                        <sup>-50%</sup>
                                    </span>
                                </div> -->
                                <div>
                                    <span class="price-new">
                                        ${service.price} ₽
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn">Оплатить ${service.price} ₽</button>
                    </div>
                    `}
                </div>
                `}

                ${step && step == 3 && $h`
                <div class="block">
                  <span href="${paymentUrl}" class="link-payment" style="display: none;">Оплатить</span>
                  <button type="submit" class="btn check-payment-status">Загрузка...</button>
                </div>
                `}
            </form>


        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '59ca087772';
export default framework7Component;