/** @jsx $jsx */
import { $jsx } from 'framework7';
// import params from "../js/params.js";
// const API_URL = params.apiUrl;
// const BACKEND_URL = params.backendUrl;

var API_URL = "https://api.may.rdbx24.ru";
var BACKEND_URL = "https://may.rdbx24.ru";
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $ = _ref.$,
    $f7 = _ref.$f7,
    $f7route = _ref.$f7route,
    $store = _ref.$store,
    $update = _ref.$update;
  var formId = $f7route.params.id;
  var order = $store.getters.order.value;
  var fssp = $store.getters.fssp.value;
  var form = props,
    is_loading = true,
    formErrors = null;
  var id = props.id,
    title = props.title,
    groups = props.groups,
    template = props.template;
  function setDateMask(selector, mask) {
    var element = document.getElementById(selector);
    if (element) var dateMask = IMask(element, {
      mask: Date // enable date mask
    });
  }
  var onSubmit = function onSubmit(e) {
    e.preventDefault();
    var formData = $f7.form.convertToData('#category-form');
    $f7.request.post("".concat(API_URL, "/v1/forms/upload?id=").concat(formId, "&order_id=").concat(order.id), formData).then(function (response) {
      formErrors = null;
      $f7.dialog.alert('Ожидайте заявление на e-mail', 'Успешно', function () {
        $store.dispatch('resetOrder');
        $store.dispatch('resetFssp');
        $f7.tab.show("#view-home");
        $f7.views.home.router.navigate("/");
      });
      $update();
    }).catch(function (err) {
      var error_response = JSON.parse(err.xhr.response);
      formErrors = error_response;
      $update();
    });
  };
  $on('pageInit', function () {
    groups.map(function (group) {
      group.fields.map(function (field) {
        if (field.type == 'date' && field.mask) setDateMask("field-".concat(field.code));
      });
    });
    if (fssp) $f7.form.fillFromData('#category-form', fssp);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page">

        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#" class="back link">
                        <i class="icn icn-back"></i>
                        <span>Главная</span>
                    </a>
                </div>
                <div class="title">
                    Заполнение полей
                </div>
                <div class="right">
                    <a href="#" class="link link-telegram">
                        <i class="icn icn-telegram"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="page-content">
            <div class="block">
                ${form && form.description && $h`
                <p>
                    ${form.description}
                </p>
                `}

                <form @submit=${onSubmit} id="category-form">
                    ${form && $h`
                    <div>
                        ${groups.map((group) => $h`
                        <div>
                            <h3>${group.title}</h3>

                            <div class="row">
                                ${group.fields.map((field) => $h`
                                <div class="form-row ${field.size ? field.size : 'col-100'}">
                                    ${field.description && $h`
                                    <p>${field.description}</p>
                                    `}

                                    <div
                                        class="input-wrap ${formErrors && formErrors[field.code] ? 'input-wrap-error' : ''}">
                                        <label>${field.title}</label>
                                        <input id="field-${field.code}" name="${field.code}"
                                            type="${field.type == 'date' ? 'text' : field.type}"
                                            placeholder="${field.mask}" ${field.required && $h`required`}
                                            ${field.required && $h`validate`} />

                                        ${field.type == 'date' && $h`
                                        <i class="icn icn-calendar"></i>
                                        `}
                                    </div>
                                </div>
                                `)}
                            </div>
                        </div>
                        `)}
                    </div>
                    `}

                    <div>
                        <button type="submit" class="btn">Отправить</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
`
    }
    ;
}
framework7Component.id = 'ebe9cdc109';
export default framework7Component;