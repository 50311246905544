/** @jsx $jsx */
import { $jsx } from 'framework7';
var API_URL = "https://api.may.rdbx24.ru";
var BACKEND_URL = "https://may.rdbx24.ru";
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $ = _ref.$,
    $f7 = _ref.$f7,
    $f7route = _ref.$f7route,
    $store = _ref.$store,
    $update = _ref.$update;
  var id = props.id,
    description = props.description,
    groups = props.groups;
  var formId = $f7route.params.id;
  var form = props,
    is_loading = true,
    formErrors = null,
    timerId = null;
  function setDateMask(selector, mask) {
    var element = document.getElementById(selector);
    if (element) var dateMask = IMask(element, {
      mask: Date // enable date mask
    });
  }
  var onGetResult = function onGetResult(formData) {
    $f7.request.post("".concat(API_URL, "/v1/forms/upload?id=").concat(formId), formData).then(function (response) {
      $store.dispatch('updateFssp', formData);
      var responseData = JSON.parse(response.data);
      if (responseData && responseData.result && responseData.result.status === "success") {
        clearInterval(timerId);
        $f7.dialog.close();
        if (responseData.items && !responseData.items.length) {
          $f7.dialog.alert('У вас нет задолженностей', 'Успешно', function () {
            $store.dispatch('resetFssp');
            $f7.tab.show("#view-home");
            $f7.views.home.router.navigate("/", {
              clearPreviousHistory: true,
              ignoreCache: true
            });
          });
        }
        if (responseData.items && responseData.items.length) {
          $store.dispatch('updateFssp', {
            items: responseData.items
          });
          $f7.tab.show("#view-fssp");
          $f7.views.fssp.router.navigate("/fssp/");
        }
      }
      $update();
    }).catch(function (err) {
      clearInterval(timerId);
      $f7.dialog.close();
      $f7.dialog.alert('Произошла ошибка проверки. Попробуйте позже.', 'Ошибка');
      $update();
    });
  };
  var onSubmit = function onSubmit(e) {
    e.preventDefault();
    var formData = $f7.form.convertToData('#fssp-view-form');
    $f7.dialog.preloader('Проверка...');
    $f7.request.post("".concat(API_URL, "/v1/forms/upload?id=").concat(formId), formData).then(function (response) {
      formErrors = null;
      var responseData = JSON.parse(response.data);
      var task = responseData.result ? responseData.result.response.task : null;
      if (task) {
        formData.task = task;
        timerId = setInterval(function () {
          return onGetResult(formData);
        }, 5000);
      }
      setTimeout(function () {
        if (timerId) clearInterval(timerId);
      }, 60000);
    }).catch(function (err) {
      var error_response = JSON.parse(err.xhr.response);
      formErrors = error_response;
      $f7.dialog.close();
      $update();
    });
  };
  $on('pageInit', function () {
    groups.map(function (group) {
      group.fields.map(function (field) {
        if (field.type == 'date' && field.mask) setDateMask("field-".concat(field.code));
      });
    });

    // $f7.form.fillFromData('#fssp-view-form', {
    //     firstname: 'Алексей',
    //     lastname: 'Желтяков',
    //     secondname: 'Михайлович',
    //     birthdate: '23.04.1974',
    // });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page">

        <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
                <div class="left">
                    <a href="#view-home" class="tab-link link">
                        <i class="icn icn-back"></i>
                        <span>Главная</span>
                    </a>
                </div>
                <div class="title">Проверка задолженности</div>
            </div>
        </div>

        <div class="page-content">
            <div class="block">
                ${form && form.description && $h`
                <p>
                    ${form.description}
                </p>
                `}

                <form @submit=${onSubmit} id="fssp-view-form">
                    ${form && $h`
                    <div>
                        ${groups.map((group) => $h`
                        <div>
                            <h3>${group.title}</h3>

                            <div class="row">
                                ${group.fields.map((field) => $h`
                                <div class="form-row ${field.size ? field.size : 'col-100'}">
                                    ${field.description && $h`
                                    <p>${field.description}</p>
                                    `}

                                    ${field.type == 'select' && $h`
                                    <div
                                        class="input-wrap ${formErrors && formErrors[field.code] ? 'input-wrap-error' : ''}">
                                        <a class="smart-select smart-select-init" data-searchbar="true"
                                            data-searchbar-placeholder="Поиск" data-searchbar-disable-button="false"
                                            data-page-back-link-text="Назад" data-close-on-select="true">
                                            <select name="${field.code}">
                                                ${field.options.map((option) => $h`
                                                <option value="${option.value}">${option.label}</option>
                                                `)}
                                            </select>

                                            <div class="item-content">
                                                <div class="item-inner">
                                                    <div class="item-title">${field.title}</div>
                                                    <i class="icn icn-next"></i>
                                                </div>
                                            </div>
                                        </a>
                                        
                                    </div>
                                    `}

                                    ${field.type != 'select' && $h`
                                    <div
                                        class="input-wrap ${formErrors && formErrors[field.code] ? 'input-wrap-error' : ''}">
                                        <label>${field.title}</label>
                                        <input id="field-${field.code}" name="${field.code}"
                                            type="${field.type == 'date' ? 'text' : field.type}"
                                            placeholder="${field.mask}" ${field.required && $h`required`}
                                            ${field.required && $h`validate`} />

                                        ${field.type == 'date' && $h`
                                        <i class="icn icn-calendar"></i>
                                        `}
                                    </div>
                                    `}

                                </div>
                                `)}
                            </div>
                        </div>
                        `)}
                    </div>
                    `}

                    <div>
                        <button type="submit" class="btn">Проверить</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
`
    }
    ;
}
framework7Component.id = '624bd8721b';
export default framework7Component;