import HomePage from '../pages/home.f7.html';
import NotFoundPage from '../pages/404.f7.html';

import CategoryPage from '../pages/category.f7.html';
import FormViewPage from '../pages/form-view.f7.html';
import ServiceViewPage from '../pages/service-view.f7.html';

import QuizPage from '../pages/quiz.f7.html';
import QuizViewPage from '../pages/quiz-view.f7.html';
import QuizSuccessPage from '../pages/quiz-success.f7.html';

import FsspPage from '../pages/fssp.f7.html';
import FsspViewPage from '../pages/fssp-view.f7.html';
import FsspSuccessPage from '../pages/fssp-success.f7.html';

import {
  request
} from 'framework7';

import params from "../js/params.js";
const API_URL = params.apiUrl;

const routeResolve = (resolve, component, props) => resolve({
  component: component
}, {
  props: props
});

var routes = [{
    path: '/',
    component: HomePage,
  },
  {
    path: '/category/:id/',
    async: function ({
      router,
      to,
      resolve
    }) {
      var categoryId = to.params.id;

      request.json(`${API_URL}/v1/categories/${categoryId}`, {
        expand: 'parent,categories,posts,thumbnailUrl,form,services'
      }).then((response) => routeResolve(resolve, CategoryPage, response.data));
    }
  },

  {
    path: '/quiz/',
    component: QuizPage,
  },
  {
    path: '/quiz/success/',
    component: QuizSuccessPage,
  },
  {
    path: '/quiz/:id/',
    async: function ({
      router,
      to,
      resolve
    }) {
      var formId = to.params.id;

      request.json(`${API_URL}/v1/forms/${formId}`, {
        expand: 'groups,groups.fields'
      }).then((response) => routeResolve(resolve, QuizViewPage, response.data));
    }
  },

  {
    path: '/fssp/',
    component: FsspPage,
  },
  {
    path: '/fssp/success/',
    component: FsspSuccessPage,
  },
  {
    path: '/fssp/:id/',
    async: function ({
      router,
      to,
      resolve
    }) {
      var formId = to.params.id;

      request.json(`${API_URL}/v1/forms/${formId}`, {
        expand: 'groups,groups.fields'
      }).then((response) => routeResolve(resolve, FsspViewPage, response.data));
    }
  },

  {
    path: '/form/:id/',
    async: function ({
      router,
      to,
      resolve
    }) {
      var formId = to.params.id;

      request.json(`${API_URL}/v1/forms/${formId}`, {
        expand: 'groups,groups.fields'
      }).then((response) => routeResolve(resolve, FormViewPage, response.data));
    }
  },
  {
    path: '/services/:id/',
    async: function ({
      router,
      to,
      resolve
    }) {
      var categoryId = to.params.id;

      request.json(`${API_URL}/v1/categories/${categoryId}`, {
        expand: 'form,services'
      }).then((response) => routeResolve(resolve, ServiceViewPage, response.data));
    }
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
