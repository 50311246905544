
import { createStore } from 'framework7';

const store = createStore({
  state: {
    products: [],

    order: {
      id: null,
      user_id: null,
      service_id: null,
      form_id: null,
      category_id: null,
      title: 'Новый заказ',
      phone: null,
      email: null,
    },

    step: 1,
    service: null,
    paymentUrl: null,
    services: [],


    quiz: {},
    fssp: {},
  },

  getters: {
    products({ state }) {
      return state.products;
    },
    order({ state }) {
      return state.order;
    },
    step({ state }) {
      return state.step;
    },
    paymentUrl({ state }) {
      return state.paymentUrl;
    },
    service({ state }) {
      return state.service;
    },
    services({ state }) {
      return state.services;
    },
    quiz({ state }) {
      return state.quiz;
    },
    fssp({ state }) {
      return state.fssp;
    }
  },
  actions: {
    addProduct({ state }, product) {
      state.products = [...state.products, product];
    },
    addOrder({ state }, order) {
      state.order = order;
    },
    updateOrder({ state }, fields) {
      for (var field in fields) {
        state.order[field] = fields[field];
      }
    },
    updateStep({ state }, step) {
      state.step = step;
    },
    updatePaymentUrl({ state }, paymentUrl) {
      state.paymentUrl = paymentUrl;
    },
    updateService({ state }, service) {
      state.service = service;
    },
    updateServices({ state }, services) {
      state.services = services;
    },
    updateQuiz({ state }, fields) {
      for (var field in fields) {
        state.quiz[field] = fields[field];
      }
    },
    resetQuiz({ state }) {
      state.quiz = {};
    },
    updateFssp({ state }, fields) {
      for (var field in fields) {
        state.fssp[field] = fields[field];
      }
    },
    resetFssp({ state }) {
      state.fssp = {};
    },
    resetOrder({ state }) {
      state.order = {
        id: null,
        user_id: null,
        service_id: null,
        form_id: null,
        category_id: null,
        title: 'Новый заказ',
        phone: null,
        email: null,
      };
    }
  },
})
export default store;
