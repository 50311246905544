/** @jsx $jsx */
import { $jsx } from 'framework7';
// import params from "../js/params.js";
// const API_URL = params.apiUrl;
// const BACKEND_URL = params.backendUrl;

var API_URL = "https://api.may.rdbx24.ru";
var BACKEND_URL = "https://may.rdbx24.ru";
function framework7Component(props, _ref) {
  var $on = _ref.$on,
    $ = _ref.$,
    $f7 = _ref.$f7,
    $f7router = _ref.$f7router,
    $onMounted = _ref.$onMounted,
    $store = _ref.$store,
    $update = _ref.$update;
  var id = props.id,
    form_id = props.form_id,
    form = props.form,
    title = props.title,
    thumbnail_id = props.thumbnail_id,
    description = props.description,
    short_description = props.short_description,
    parent = props.parent,
    categories = props.categories,
    services = props.services,
    posts = props.posts;
  $onMounted(function () {
    $('.category-view').html(description);
  });
  var fileUrl = BACKEND_URL;
  var quiz = null;
  var fssp = null;
  var is_loading = true;
  var swiperPosts = null;
  var service = $store.getters.service.value;
  var step = $store.getters.step.value;
  var onQuizSubmit = function onQuizSubmit(e) {
    e.preventDefault();
    var formData = $f7.form.convertToData('#category-quiz-form');
    $f7.request.post("".concat(API_URL, "/v1/forms/upload?id=").concat(form_id), formData).then(function (response) {
      var responseData = JSON.parse(response.data);
      if (responseData.form_id && responseData.result != 'call') {
        $store.dispatch('updateServices', services);
        $store.dispatch('updateOrder', {
          category_id: id,
          form_id: responseData.form_id
        });
        $update().then(function () {
          $store.dispatch('updateStep', 1);
          $f7.tab.show("#view-services");
          $f7.views.services.router.navigate("/services/".concat(id, "/"));
        });
      }
      if (responseData.form_id && responseData.result == 'call') {
        $store.dispatch('updateQuiz', responseData);
        $f7.tab.show("#view-quiz");
        $f7.views.quiz.router.navigate("/quiz/");
      }
    }).catch(function (err) {
      $f7.dialog.alert('Ошибка отправки формы', 'Ошибка');
    });
  };
  var openServices = function openServices() {
    $store.dispatch('updateServices', services);
    $store.dispatch('updateOrder', {
      category_id: id,
      form_id: form_id
    });
    $update().then(function () {
      $store.dispatch('updateStep', 1);
      $f7.tab.show("#view-services");
      $f7.views.services.router.navigate("/services/".concat(id, "/"));
    });
  };
  var openFssp = function openFssp() {
    $store.dispatch('updateOrder', {
      category_id: id,
      form_id: form_id
    });
    $update().then(function () {
      $f7.tab.show("#view-fssp");
      $f7.views.fssp.router.navigate("/fssp/".concat(form_id, "/"));
    });
  };
  var openPost = function openPost(item) {
    $f7.request.json("".concat(API_URL, "/v1/posts/").concat(item.id), {
      expand: 'posts'
    }).then(function (response) {
      var post = response.data;
      var swiperContent = '';
      if (post.posts && post.posts.length) {
        swiperContent = "<div class=\"swiper-container swiper-posts mb-5\">\n            <div class=\"swiper-wrapper\">\n          ";
        post.posts.map(function (p) {
          swiperContent += "<div class=\"swiper-slide\">\n              <h2>".concat(p.title, "</h2>\n              ").concat(p.description, "\n            </div>");
        });
        swiperContent += "\n            </div>\n          </div>\n\n          <div class=\"swiper-buttons-height\"></div>\n          <div class=\"swiper-buttons row\">\n            <div class=\"col-50\">\n              <button type=\"button\" class=\"btn btn-disabled swiper-prev\">\u041D\u0430\u0437\u0430\u0434</button>\n            </div>\n            <div class=\"col-50\">\n              <button type=\"button\" class=\"btn swiper-next\">\u0414\u0430\u043B\u0435\u0435</button>\n            </div>\n          </div>\n          ";
      }
      var dynamicSheet = $f7.sheet.create({
        content: "\n            <div class=\"sheet-modal my-sheet-swipe-to-close\" style=\"height:auto;\">\n              <div class=\"sheet-modal-inner\">\n                <div class=\"post-view page-content\">\n                  <div class=\"post-view-title block-title\">".concat(post.title, "</div>\n                  <div class=\"post-view-content block\">\n                    <p>").concat(post.description, "</p>\n                    ").concat(swiperContent, "\n                  </div>\n                </div>\n              </div>\n            </div>\n          "),
        swipeToClose: true,
        backdrop: true,
        on: {
          open: function open() {
            console.log('dynamicSheet:open');
            swiperPosts = $f7.swiper.create('.swiper-posts', {
              autoHeight: true
            });
          },
          close: function close() {
            console.log('dynamicSheet:close');
            $f7.swiper.destroy('.swiper-posts');
            swiperPosts = null;
          }
        }
      });
      dynamicSheet.open();
    });
  };
  $on('pageInit', function () {
    if (form && (form.template === "quiz" || form.template === "fssp")) {
      $f7.request.json("".concat(API_URL, "/v1/forms/").concat(form_id), {
        expand: 'groups,groups.fields'
      }).then(function (response) {
        if (form.template === "quiz") quiz = response.data;
        if (form.template === "fssp") fssp = response.data;
        $update();
      }).finally(function () {
        is_loading = false;
        $update();
      });
    } else {
      is_loading = false;
      $update();
    }
  });
  $(document).on("click", ".swiper-next", function () {
    if (swiperPosts) {
      swiperPosts.slideNext();
      if (swiperPosts.progress == 1) {
        $(".swiper-next").addClass('btn-disabled');
      } else {
        $(".swiper-next").removeClass('btn-disabled');
      }
      if (swiperPosts.progress > 0) {
        $(".swiper-prev").removeClass('btn-disabled');
      } else {
        $(".swiper-prev").addClass('btn-disabled');
      }
    }
  });
  $(document).on("click", ".swiper-prev", function () {
    if (swiperPosts) {
      swiperPosts.slidePrev();
      if (swiperPosts.progress == 0) {
        $(".swiper-prev").addClass('btn-disabled');
      } else {
        $(".swiper-prev").removeClass('btn-disabled');
      }
      if (swiperPosts.progress < 1) {
        $(".swiper-next").removeClass('btn-disabled');
      } else {
        $(".swiper-next").addClass('btn-disabled');
      }
    }
  });
  $(document).on("change", ".quiz-item input", function () {
    var input_name = $(this).attr('name');
    $(this).closest(".quiz-item").find("input:not([name='" + input_name + "'])").prop("checked", false);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page">

    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="back link">
            <i class="icn icn-back"></i>
            <span>Назад</span>
          </a>
        </div>
        <div class="title">
          ${parent && $h`
          <small>${parent.title}</small><br />
          `}
          ${title}
        </div>
        <div class="right">
          <a href="#" class="link link-telegram">
            <i class="icn icn-telegram"></i>
        </a>
        </div>
      </div>
    </div>

    <div class="page-content">

      <div class="block category-view">
        <!-- <p>${description}</p> -->
      </div>

      ${form && form.template == 'fssp' && $h`
        <div class="block">
          <a href="#" class="btn" @click="${() => openFssp()}">Проверить задолженность</a>

          ${form.short_description && $h`
          <div class="block-information">
            <i class="icn icn-info"></i>
            ${form.short_description}
          </div>
          `}
        </div>
      `}

      <div class="block block-grid">
        ${categories.map((item) => $h`
        <a class="category-card" href="/category/${item.id}/">
          <div class="category-card-title">${item.title}</div>
          <div class="category-card-text">${item.short_description}</div>
          <i class="icn icn-next"></i>
        </a>
        `)}
      </div>

      ${posts && $h`
      <div class="block block-grid">
        ${posts.map((item) => $h`
        <a class="post-card" href="#" @click="${() => openPost(item)}">
          <div class="post-card-title">${item.title}</div>
          <i class="icn icn-next"></i>
        </a>
        `)}
      </div>
      `}

      ${quiz && $h`
      <div class="block">
        <form @submit=${onQuizSubmit} id="category-quiz-form">
          ${quiz.groups.map((group) => $h`
          <div class="quiz-item">
            <div class="quiz-item-title">${group.title}</div>

            ${group.fields.map((field) => $h`
            <!-- <label class="quiz-checkbox">
              <input type="${field.type}" name="${field.code}" value="${field.value}" />
              <i class="icn icn-checkbox"></i>
              <div class="quiz-checkbox-label">${field.title}</div>
            </label> -->

            <div class="quiz-checkbox">
              <label class="toggle toggle-init color-green">
                <input type="${field.type}" name="${field.code}" value="${field.value}" />
                <span class="toggle-icon"></span>
              </label>
              <div class="quiz-checkbox-label">${field.title}</div>
            </div>
           
            `)}
          </div>
          `)}
          <div>
            <button type="submit" class="btn">Отправить</button>
          </div>
        </form>
      </div>
      `}

      ${form && form.template != 'quiz' && form.template != 'fssp' && $h`
      <div class="block">
        <a href="#" class="btn" @click="${() => openServices()}">Получить заявление</a>

        ${form.short_description && $h`
        <div class="block-information">
          <i class="icn icn-info"></i>
          ${form.short_description}
        </div>
        `}
      </div>
      `}

      <img class="category-view-image" src="${fileUrl}/index.php?r=site/file&id=${thumbnail_id}" alt="" />
    </div>

  </div>
`
    }
    ;
}
framework7Component.id = '47c1d0d30d';
export default framework7Component;